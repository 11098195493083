// If you don't want to use TypeScript you can delete this file!
import React, { useEffect, useState } from "react"
import { PageProps, Link, graphql, navigate, useStaticQuery } from "gatsby"
import ReactMarkdown from 'react-markdown'

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar/navBar"
import useAuth from "../hooks/useAuth"
import { createAccount } from "../backendApi/userApi"



function JoinUsPage() {
  const { isAuthenticated } = useAuth()
  if (isAuthenticated) {
      navigate('/')
  }

  const { strapiMembershipApplication } = useStaticQuery(graphql`
  {
    strapiMembershipApplication {
      description {
        data {
          description
        }
      }
    }
  }`)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [wsgMembershipStatus, setWsgMembershipStatus] = useState('temporary')
  const [wsgMembershipType, setWsgMembershipType] = useState('temporary')
  const [bcaMembershipType, setBcaMembershipType] = useState('temporary')
  const [bcaNumber, setBcaNumber] = useState('')
  const [firstName, setFirstName] = useState('')
  const [otherName, setOtherName] = useState('')
  const [lastName, setLastName] = useState('')
  const [gender, setGender] = useState('prefer-not-to-say')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [addressLineOne, setAddressLineOne] = useState('')
  const [addressLineTwo, setAddressLineTwo] = useState('')
  const [county, setCounty] = useState('')
  const [postcode, setPostcode] = useState('')
  const [phoneNumberOne, setPhoneNumberOne] = useState('')
  const [phoneNumberTwo, setPhoneNumberTwo] = useState('')

  const [ec1FirstName, setEc1FirstName] = useState('')
  const [ec1LastName, setEc1LastName] = useState('')
  const [ec1PhoneNumber, setEc1PhoneNumber] = useState('')
  const [ec1Relation, setEc1Relation] = useState('')

  const [ec2FirstName, setEc2FirstName] = useState('')
  const [ec2LastName, setEc2LastName] = useState('')
  const [ec2PhoneNumber, setEc2PhoneNumber] = useState('')
  const [ec2Relation, setEc2Relation] = useState('')

  const handler = func => event => func(event.target.value)


  const handleSubmit = async (event) => {
    event.preventDefault()
    const emergencyContactOne = {
      firstName: ec1FirstName,
      lastName: ec1LastName,
      phoneNumber: ec1PhoneNumber,
      relation: ec1Relation
    }
    const emergencyContactTwo = {
      firstName: ec2FirstName,
      lastName: ec2LastName,
      phoneNumber: ec2PhoneNumber,
      relation: ec2Relation
    }
    const address = {
      addressLineOne,
      addressLineTwo,
      county,
      postcode
    }
    const personalDetails = {
      firstName,
      otherName,
      lastName,
      wsgMembershipStatus,
      wsgMembershipType,
      bcaMembershipType,
      bcaNumber,
      gender,
      dateOfBirth,
      phoneNumberOne,
      phoneNumberTwo,
      emergencyContact: [
        emergencyContactOne,
        emergencyContactTwo
      ],
      address
    }
    const accountDetails = {
      username: email,
      email,
      password,
      personalDetails,
    };

    (async () => {
        const res = await createAccount(accountDetails)
        if ("jwt" in res) {
          alert("Account successfully created. Please login")
          navigate("/login")
        } else {
          alert(res?.error?.message)
        }
    })()
  }
  
  return (
    <Layout>
      <Seo title="Temporary / Provisional Membership Application" />
      <NavBar />
      <ReactMarkdown>{strapiMembershipApplication.description.data.description}</ReactMarkdown>
      <form onSubmit={handleSubmit}>
        <h3>
          Login details
        </h3>
        <div>
          <label>
            Email (this is your username as well):
            <input required onChange={handler(setEmail)} value={email} type="email"/>
          </label>
        </div>
        <div>
          <label>
            Password:
            <input required onChange={handler(setPassword)} value={password} type="password"/>
          </label>
        </div>
        <h3>Membership type</h3>
        <div>
          <label>
            WSG Membership status:
            <select 
                onChange={handler(setWsgMembershipStatus)}
                value={wsgMembershipStatus}
                required
              >
                <option value="temporary">Temporary</option>
                <option value="provisional">Provisional</option>
                <option value="full">Full</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            WSG Membership type:
            <select 
                onChange={handler(setWsgMembershipType)}
                value={wsgMembershipType}
                required
              >
                <option value="temporary">Temporary</option>
                <option value="full">Standard</option>
                <option value="reduced">Reduced rate (student / benefits / non-working)</option>
                <option value="joint">Joint (two people living together)</option>
                <option value="honorary">Honorary member</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            BCA Membership type:
            <select 
                onChange={handler(setBcaMembershipType)}
                value={bcaMembershipType}
                required
              >
                <option value="temporary">Temporary</option>
                <option value="caving">Caving</option>
                <option value="non-caving">Non-Caving</option>
                <option value="student">Student</option>
                <option value="under-18">Under-18</option>
                <option value="direct">Direct Individual Member</option>
                <option value="other-club">Other club</option>
            </select>          
          </label>
          { (bcaMembershipType == 'direct' || bcaMembershipType == 'other-club') && 
            <div>
              <label>
                Enter your BCA number
                <input onChange={handler(setBcaNumber)} value={bcaNumber} type="text"/>
              </label>
            </div>
          }
        </div>
        <h3>Your details</h3>
        <div>
          <label>
            First Name:
            <input required onChange={handler(setFirstName)} value={firstName} type="text"/>
          </label>
        </div>
        <div>
          <label>
            Other Names:
            <input onChange={handler(setOtherName)} value={otherName} type="text"/>
          </label>
        </div>
        <div>
          <label>
            Last Name:
            <input required onChange={handler(setLastName)} value={lastName} type="text"/>
          </label>
        </div>
        <div>
          <label>
            Gender:
            <select 
                onChange={handler(setGender)}
                value={gender}
                required
              >
                <option value="prefer-not-to-say">Prefer not to say</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="transgender">Transgender</option>
                <option value="other">Other</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Date of birth:
            <input required onChange={handler(setDateOfBirth)} value={dateOfBirth} type="date"/>
          </label>
        </div>
        <div>
          <label>
            Address Line 1:
            <input required onChange={handler(setAddressLineOne)} value={addressLineOne} type="text" name="address line 1" />
          </label>
        </div>
        <div>
          <label>
            Address Line 2:
            <input onChange={handler(setAddressLineTwo)} value={addressLineTwo} type="text" name="address line 2" />
          </label>
        </div>
        <div>
          <label>
            County:
            <input onChange={handler(setCounty)} value={county} type="text" name="county" />
          </label>
        </div>
        <div>
        <label>
            Postcode:
            <input required onChange={handler(setPostcode)} value={postcode} type="text" name="postcode" />
          </label>
        </div>
        <div>
        <label>
            Primary phone number:
            <input required onChange={handler(setPhoneNumberOne)} value={phoneNumberOne} type="text" name="primary phone number" />
          </label>
        </div>
        <div>
        <label>
            Secondary phone number (optional):
            <input onChange={handler(setPhoneNumberTwo)} value={phoneNumberTwo} type="text" name="secondary phone number" />
          </label>
        </div>
        <h3>Emergency contact information (1)</h3>
        <div>
          <label>
            First Name:
            <input required onChange={handler(setEc1FirstName)} value={ec1FirstName} type="text" name="firstname" />
          </label>
        </div>
        <div>
        <label>
            Last Name:
            <input required onChange={handler(setEc1LastName)} value={ec1LastName} type="text" name="lastname" />
          </label>
        </div>
        <div>
          <label>
            Primary phone number:
            <input required onChange={handler(setEc1PhoneNumber)} value={ec1PhoneNumber} type="text" name="primary phone number" />
          </label>
        </div>
        <div>
          <label>
            Relation (optional):
            <input onChange={handler(setEc1Relation)} value={ec1Relation} type="text" name="relation" />
          </label>
        </div>
        <h3>Emergency contact information (2) - optional</h3>
        <div>
          <label>
            First Name:
            <input onChange={handler(setEc2FirstName)} value={ec2FirstName} type="text" name="firstname" />
          </label>
        </div>
        <div>
        <label>
            Last Name:
            <input onChange={handler(setEc2LastName)} value={ec2LastName} type="text" name="lastname" />
          </label>
        </div>
        <div>
          <label>
            Primary phone number:
            <input onChange={handler(setEc2PhoneNumber)} value={ec2PhoneNumber} type="text" name="primary phone number" />
          </label>
        </div>
        <div>
          <label>
            Relation:
            <input onChange={handler(setEc2Relation)} value={ec2Relation} type="text" name="relation" />
          </label>
        </div>
          

        <button>Submit</button>
      </form>
    </Layout>
  )
}

export default JoinUsPage
